import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Challenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [filteredChallenges, setFilteredChallenges] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState({ field: "appName", order: "asc" });
  const navigate = useNavigate();

  // Fetch challenges from Firestore
  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const challengesRef = collection(db, "challenges");
        const challengesSnap = await getDocs(challengesRef);

        const challengeList = challengesSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setChallenges(challengeList);
        setFilteredChallenges(challengeList);
      } catch (error) {
      }
    };

    fetchChallenges();
  }, []);

  // Filter challenges by search term
  useEffect(() => {
    const filtered = challenges.filter((challenge) =>
      (challenge.appName || "").toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredChallenges(filtered);
  }, [searchTerm, challenges]);

  // Sort challenges by appName
  const handleSort = () => {
    const order = sortBy.order === "asc" ? "desc" : "asc";
    const sorted = [...filteredChallenges].sort((a, b) => {
      const appNameA = a.appName || "";
      const appNameB = b.appName || "";
      return order === "asc"
        ? appNameA.localeCompare(appNameB)
        : appNameB.localeCompare(appNameA);
    });

    setSortBy({ field: "appName", order });
    setFilteredChallenges(sorted);
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Challenges</h1>

      {/* Create Challenge Button */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by app name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => navigate("/challenges/create")}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Create Challenge
        </button>
      </div>

      {/* Challenges Table */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th
              className="border border-gray-300 px-4 py-2 text-left cursor-pointer"
              onClick={handleSort}
            >
              App Name {sortBy.order === "asc" ? "▲" : "▼"}
            </th>
            <th className="border border-gray-300 px-4 py-2 text-center w-20">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredChallenges.map((challenge) => (
            <tr key={challenge.id}>
              {/* App Name */}
              <td className="border border-gray-300 px-4 py-2">
                {challenge.appName || "Unknown App"}
              </td>

              {/* Edit Button */}
              <td className="border border-gray-300 px-4 py-2 text-center">
                <button
                  onClick={() => navigate(`/challenges/edit/${challenge.id}`)}
                  className="bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600 transition"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Challenges;
