import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext.js";

import Index from "./pages/Index.jsx";
import MyApps from "./pages/MyApps.jsx";
import Campaigns from "./pages/Campaigns.jsx";
import CampaignDetails from "./pages/CampaignDetails.jsx";
import Challenges from "./pages/Challenges.jsx";
import CreateCampaign from "./pages/CreateCampaign.jsx";
import CreateChallenge from "./pages/CreateChallenge.jsx";
import ManageCampaign from "./pages/ManageCampaign.jsx";
import ConsolePage from "./pages/ConsolePage.jsx";
import SignUpPage from "./pages/SignUpPage.jsx";
import Analytics from "./pages/Analytics.jsx";
import Settings from "./pages/Settings.jsx";
import Gamification from "./pages/Gamification.jsx";
import LevelManagement from "./pages/LevelManagement.jsx";
import Qwests from "./pages/Qwests.jsx";
import Users from "./pages/Users.jsx";
import Achievements from "./pages/Achievements";
import CreateAchievement from "./pages/CreateAchievement.jsx";




import MainLayout from "./components/MainLayout.jsx";
import PublicLayout from "./components/PublicLayout.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Public Routes */}
                    <Route element={<PublicLayout />}>
                        <Route path="/" element={<Index />} />
                        <Route path="/signup" element={<SignUpPage />} />
                    </Route>

                    {/* Protected Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route element={<MainLayout />}>
                            <Route path="/dashboard" element={<ConsolePage />} />
                            <Route path="/myapps" element={<MyApps />} />
                            <Route path="/campaigns" element={<Campaigns />} />
                            <Route path="/campaigns/create" element={<CreateCampaign />} />
                            <Route path="/campaigns/manage" element={<ManageCampaign />} />
                            <Route path="/campaigns/:id" element={<CampaignDetails />} />
                            <Route path="/challenges" element={<Challenges />} />
                            <Route path="/challenges/create" element={<CreateChallenge />} />
                            <Route path="/analytics" element={<Analytics />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/gamification" element={<Gamification />} />
                            <Route path="/gamification/level-management" element={<LevelManagement />} />
                            <Route path="/gamification/qwests" element={<Qwests />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/gamification/achievements" element={<Achievements />} />
                            <Route path="/gamification/achievements/create" element={<CreateAchievement />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
