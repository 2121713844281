import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

const MainLayout = () => {
    console.log("Rendering MainLayout");

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <Header />

            {/* Sidebar and Main Content */}
            <div className="flex flex-1">
                <Sidebar />
                <div className="flex-1 bg-gray-100 p-8">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
