import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import playIcon from "../images/play.png"; // Path to your play icon
import pauseIcon from "../images/pause.png"; // Path to your pause icon

const Campaigns = () => {
      console.log("Rendering Campaigns component");

  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState({ field: "name", order: "asc" });
  const navigate = useNavigate();

  // Fetch campaigns from Firestore
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const campaignsRef = collection(db, "campaigns");
        const campaignsSnap = await getDocs(campaignsRef);

        const campaignList = campaignsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCampaigns(campaignList);
        setFilteredCampaigns(campaignList);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  // Filter campaigns by search term
  useEffect(() => {
    const filtered = campaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredCampaigns(filtered);
  }, [searchTerm, campaigns]);

  // Sort campaigns by selected field
  const handleSort = (field) => {
    const order = sortBy.field === field && sortBy.order === "asc" ? "desc" : "asc";
    const sorted = [...filteredCampaigns].sort((a, b) => {
      if (field === "budget") {
        return order === "asc" ? a.budget - b.budget : b.budget - a.budget;
      }
      if (field === "name") {
        return order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });

    setSortBy({ field, order });
    setFilteredCampaigns(sorted);
  };

  // Toggle play/pause state
  const togglePlayPause = async (campaignId, currentState, setCampaigns) => {
    try {
      const campaignRef = doc(db, "campaigns", campaignId);
      await updateDoc(campaignRef, { play: !currentState });

      // Update the local state
      setCampaigns((prev) =>
        prev.map((campaign) =>
          campaign.id === campaignId ? { ...campaign, play: !currentState } : campaign
        )
      );
    } catch (error) {
      console.error("Error toggling play/pause:", error);
    }
  };

  // Calculate budget progression percentage
  const getBudgetProgress = (budgetUsed, budget) => {
    return Math.min(((budgetUsed || 0) / budget) * 100, 100).toFixed(0);
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Campaigns</h1>

      {/* Create Campaign Button */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => navigate("/campaigns/create")}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Create Campaign
        </button>
      </div>

      {/* Campaign Table */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left w-12"></th>
            <th
              className="border border-gray-300 px-4 py-2 text-left cursor-pointer"
              onClick={() => handleSort("name")}
            >
              Name {sortBy.field === "name" ? (sortBy.order === "asc" ? "▲" : "▼") : ""}
            </th>
            <th
              className="border border-gray-300 px-4 py-2 text-left cursor-pointer w-28"
              onClick={() => handleSort("budget")}
            >
              Budget{" "}
              {sortBy.field === "budget" ? (sortBy.order === "asc" ? "▲" : "▼") : ""}
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left w-40">
              Progression
            </th>
          </tr>
        </thead>
        <tbody>
  {filteredCampaigns.map((campaign) => (
    <tr key={campaign.id}>
      {/* Play/Pause Icon */}
      <td className="border border-gray-300 px-4 py-2 text-center">
        <button
          onClick={() => togglePlayPause(campaign.id, campaign.play, setCampaigns)}
          className="focus:outline-none"
        >
          <img
            src={campaign.play ? pauseIcon : playIcon}
            alt={campaign.play ? "Pause" : "Play"}
                    className="w-6 h-6"
                    style={{ width: "12px", height: "12px", objectFit: "cover" }}   
          />
        </button>
      </td>

      {/* Name (Clickable) */}
      <td className="border border-gray-300 px-4 py-2">
        <button
          onClick={() => navigate(`/campaigns/${campaign.id}`)}
          className="text-blue-500 hover:underline"
        >
          {campaign.name}
        </button>
      </td>

      {/* Budget */}
      <td className="border border-gray-300 px-4 py-2 text-right">{campaign.budget}$</td>

      {/* Budget Progression */}
              <td className="border border-gray-300 px-4 py-2">
                <div className="flex items-center space-x-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-green-500 h-2 rounded-full"
                      style={{
                        width: `${getBudgetProgress(campaign.budgetUsed, campaign.budget)}%`,
                      }}
                    ></div>
                  </div>
                  <span className="text-sm">{`${getBudgetProgress(
                    campaign.budgetUsed,
                    campaign.budget
                  )}%`}</span>
                </div>
              </td>
    </tr>
  ))}
</tbody>
      </table>
    </div>
  );
};

export default React.memo(Campaigns);
