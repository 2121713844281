import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Ensure this uses your AuthContext

const ProtectedRoute = () => {
    const { user, loading } = useAuth();

    console.log("ProtectedRoute | loading:", loading, "| user:", user);

    // While loading the authentication state, render a loading indicator
    if (loading) {
        return <h1>Loading...</h1>;
    }

    // If no user is logged in, redirect to the login page
    if (!user) {
        console.log("Redirecting unauthenticated user to /");
        return <Navigate to="/" />;
    }

    // Otherwise, render the protected route
    return <Outlet />;
};

export default ProtectedRoute;
