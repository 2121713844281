import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig.js";

const CampaignDetails = () => {
  const { id } = useParams(); // Get the campaign ID from the route
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(null); // Store campaign data
  const [loading, setLoading] = useState(true); // For loading state

  useEffect(() => {
    // Fetch campaign details by ID
    const fetchCampaign = async () => {
      try {
        const campaignRef = doc(db, "campaigns", id);
        const campaignSnap = await getDoc(campaignRef);

        if (campaignSnap.exists()) {
          setCampaign({ id: campaignSnap.id, ...campaignSnap.data() });
        } else {
          console.error("Campaign not found!");
        }
      } catch (error) {
        console.error("Error fetching campaign:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [id]);

  // Toggle play/pause state
  const togglePlayPause = async () => {
    try {
      if (!campaign) return;
      const campaignRef = doc(db, "campaigns", id);
      await updateDoc(campaignRef, { play: !campaign.play });

      setCampaign((prev) => ({ ...prev, play: !prev.play }));
    } catch (error) {
      console.error("Error toggling play/pause:", error);
    }
  };

  if (loading) {
    return <p>Loading campaign details...</p>;
  }

  if (!campaign) {
    return <p>Campaign not found!</p>;
  }

  return (
    <div className="p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        {/* Title */}
        <h1 className="text-2xl font-bold">{campaign.name}</h1>

        {/* Buttons */}
        <div className="flex space-x-4">
          {/* Play/Pause Button */}
          <button
            onClick={togglePlayPause}
            className={`px-4 py-2 rounded ${
              campaign.play ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"
            } text-white`}
          >
            {campaign.play ? "Pause Campaign" : "Play Campaign"}
          </button>

          {/* Edit Button */}
          <button
            onClick={() => navigate(`/campaigns/edit/${id}`)}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          >
            Edit Campaign
          </button>
        </div>
      </div>

      {/* Campaign Details */}
      <div className="mb-4">
        <p><strong>Start Date:</strong> {campaign.startDate || "N/A"}</p>
        <p><strong>End Date:</strong> {campaign.endDate || "N/A"}</p>
        <p><strong>Budget:</strong> €{campaign.budget || "0.00"}</p>
        <p><strong>Play Status:</strong> {campaign.play ? "Playing" : "Paused"}</p>
      </div>
    </div>
  );
};

export default CampaignDetails;
