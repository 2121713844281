import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
    const [isGamificationOpen, setGamificationOpen] = useState(false);

    const getLinkClass = ({ isActive }) =>
        `block px-4 py-2 rounded-md text-sm transition ${
            isActive
                ? "bg-blue-600 text-white font-bold shadow-md"
                : "text-gray-600 hover:bg-gray-200 hover:text-gray-900"
        }`;

    return (
        <aside className="w-64 bg-white text-gray-900 min-h-full border-r shadow-md">
            <nav className="flex flex-col mt-6 space-y-2 px-4">
                {/* Top-Level Links */}
                <NavLink to="/dashboard" className={getLinkClass}>
                    Dashboard
                </NavLink>
                <NavLink to="/myapps" className={getLinkClass}>
                    My Apps
                </NavLink>
                <NavLink to="/campaigns" className={getLinkClass}>
                    Campaigns
                </NavLink>
                <NavLink to="/challenges" className={getLinkClass}>
                    Challenges
                </NavLink>
                <NavLink to="/analytics" className={getLinkClass}>
                    Analytics
                </NavLink>
                <NavLink to="/settings" className={getLinkClass}>
                    Settings
                </NavLink>

                {/* Users Page */}
                <NavLink to="/users" className={getLinkClass}>
                    Users
                </NavLink>

                {/* Gamification Menu */}
                <div>
                    <button
                        onClick={() => setGamificationOpen(!isGamificationOpen)}
                        className="w-full text-left px-4 py-2 text-sm font-semibold transition bg-gray-100 rounded-md hover:bg-gray-200 flex items-center justify-between shadow-sm"
                    >
                        Gamification
                        <span>{isGamificationOpen ? "▲" : "▼"}</span>
                    </button>

                    {isGamificationOpen && (
                    <div className="ml-4 mt-2 space-y-1">
                        <NavLink to="/gamification/level-management" className={getLinkClass}>
                            Level Management
                        </NavLink>
                        <NavLink to="/gamification/qwests" className={getLinkClass}>
                            Qwests
                        </NavLink>
                        <NavLink to="/gamification/achievements" className={getLinkClass}>
                            Achievements
                        </NavLink>
                    </div>
                    )}
                </div>
            </nav>
        </aside>
    );
};

export default React.memo(Sidebar);
