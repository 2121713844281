import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { db, storage } from "../firebaseConfig"; // Adjust import path
import { collection, addDoc, query, orderBy, limit, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const CreateAchievement = () => {
    const [achievement, setAchievement] = useState({
        name: "",
        description: "",
        criteria: "",
        rewardValue: 0,
        image: null,
    });
    const [isUploading, setIsUploading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAchievement((prev) => ({
            ...prev,
            [name]: name === "rewardValue" ? parseInt(value) : value,
        }));
    };

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setAchievement((prev) => ({
                ...prev,
                image: file,
            }));
        }
    };

    const handleCancelImage = () => {
        setAchievement((prev) => ({
            ...prev,
            image: null,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUploading(true);

        try {
            let imageUrl = "";
            if (achievement.image) {
                // Upload image to Firebase Storage
                const storageRef = ref(storage, `achievementsImages/${Date.now()}_${achievement.image.name}`);
                const snapshot = await uploadBytes(storageRef, achievement.image);
                imageUrl = await getDownloadURL(snapshot.ref);
            }

            // Fetch the current highest ID
            const achievementsRef = collection(db, "achievements");
            const q = query(achievementsRef, orderBy("id", "desc"), limit(1));
            const snapshot = await getDocs(q);

            let newId = 1; // Default ID for the first document
            if (!snapshot.empty) {
                const highestDoc = snapshot.docs[0];
                newId = highestDoc.data().id + 1; // Increment the highest ID
            }

            // Save achievement to Firestore with the new ID
            await addDoc(achievementsRef, {
                id: newId, // Add the auto-incremented ID
                name: achievement.name,
                description: achievement.description,
                criteria: achievement.criteria,
                rewardValue: achievement.rewardValue,
                imageUrl, // Save image URL
                createdAt: new Date().toISOString(),
            });

            alert("Achievement created successfully!");
            setAchievement({
                name: "",
                description: "",
                criteria: "",
                rewardValue: 0,
                image: null,
            });
        } catch (error) {
            console.error("Error creating achievement:", error);
            alert("Failed to create achievement.");
        } finally {
            setIsUploading(false);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: "image/*",
        multiple: false,
    });

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Create Achievement</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium">Name</label>
                    <input
                        type="text"
                        name="name"
                        value={achievement.name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Description</label>
                    <textarea
                        name="description"
                        value={achievement.description}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md"
                        required
                    ></textarea>
                </div>
                <div>
                    <label className="block text-sm font-medium">Criteria</label>
                    <textarea
                        name="criteria"
                        value={achievement.criteria}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md"
                        required
                    ></textarea>
                </div>
                <div>
                    <label className="block text-sm font-medium">Reward Value</label>
                    <input
                        type="number"
                        name="rewardValue"
                        value={achievement.rewardValue}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium">Upload Image</label>
                    <div
                        {...getRootProps()}
                        className={`w-full px-4 py-6 border-2 border-dashed rounded-md text-center ${
                            isDragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
                        }`}
                    >
                        <input {...getInputProps()} />
                        {achievement.image ? (
                            <p>{achievement.image.name}</p>
                        ) : isDragActive ? (
                            <p>Drop the image here...</p>
                        ) : (
                            <p>Drag & drop an image, or click to select one</p>
                        )}
                    </div>
                    {achievement.image && (
                        <div className="mt-4">
                            <img
                                src={URL.createObjectURL(achievement.image)}
                                alt="Preview"
                                className="w-full max-h-40 object-cover rounded-md"
                            />
                            <button
                                type="button"
                                onClick={handleCancelImage}
                                className="mt-2 bg-red-600 text-white px-4 py-2 rounded-md shadow hover:bg-red-700"
                            >
                                Cancel Image
                            </button>
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    className={`bg-green-600 text-white px-4 py-2 rounded-md shadow hover:bg-green-700 ${
                        isUploading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isUploading}
                >
                    {isUploading ? "Saving..." : "Save Achievement"}
                </button>
            </form>
        </div>
    );
};

export default CreateAchievement;
