import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import playIcon from "../images/play.png"; // Adjust paths to your icons
import pauseIcon from "../images/pause.png"; // Adjust paths to your icons

const ManageCampaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Fetch campaigns from Firestore
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const campaignsRef = collection(db, "campaigns");
        const campaignsSnap = await getDocs(campaignsRef);

        const campaignList = campaignsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCampaigns(campaignList);
        setFilteredCampaigns(campaignList);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  // Filter campaigns by search term
  useEffect(() => {
    const filtered = campaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredCampaigns(filtered);
  }, [searchTerm, campaigns]);

  // Toggle play/pause state
  const togglePlayPause = async (campaignId, currentState, setCampaigns) => {
    try {
      const campaignRef = doc(db, "campaigns", campaignId);
      await updateDoc(campaignRef, { play: !currentState });

      // Update the local state
      setCampaigns((prev) =>
        prev.map((campaign) =>
          campaign.id === campaignId ? { ...campaign, play: !currentState } : campaign
        )
      );
    } catch (error) {
      console.error("Error toggling play/pause:", error);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Manage Campaigns</h1>

      {/* Search Campaigns */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Campaigns Table */}
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Play/Pause</th>
            <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left">Budget (€)</th>
            <th className="border border-gray-300 px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCampaigns.map((campaign) => (
            <tr key={campaign.id}>
              {/* Play/Pause Button */}
              <td className="border border-gray-300 px-4 py-2 text-center">
                <button
                  onClick={() => togglePlayPause(campaign.id, campaign.play, setCampaigns)}
                  className="focus:outline-none"
                >
                  <img
                    src={campaign.play ? pauseIcon : playIcon}
                    alt={campaign.play ? "Pause" : "Play"}
                    className="w-6 h-6"
                    style={{ width: "24px", height: "24px", objectFit: "cover" }}
                  />
                </button>
              </td>

              {/* Campaign Name */}
              <td className="border border-gray-300 px-4 py-2">{campaign.name}</td>

              {/* Campaign Budget */}
              <td className="border border-gray-300 px-4 py-2">{campaign.budget}</td>

              {/* Actions */}
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => navigate(`/campaigns/edit/${campaign.id}`)}
                  className="bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600 transition"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageCampaigns;
