import React, { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

useEffect(() => {
   const auth = getAuth();
const unsubscribe = onAuthStateChanged(
    auth,
    (currentUser) => {
        console.log("Firebase Auth State Changed:", currentUser);
        setUser(currentUser);
        setLoading(false);
    },
    (error) => {
        console.error("Error in Auth State Change:", error);
        setLoading(false); // Avoid being stuck in "loading" forever
    }
);


    return () => unsubscribe();
}, []);


    return (
        <AuthContext.Provider value={{ user, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);
