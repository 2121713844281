import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";

const Header = () => {
    console.log("Rendering Header");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    // Ensure the auth state listener is only set up once and doesn't cause unnecessary re-renders
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                console.log("User logged in, currentUser:", currentUser.email);
                // Avoid navigation loop
                if (window.location.pathname === "/") {
                    navigate("/dashboard");
                }
            }
        });

        return () => unsubscribe(); // Clean up the listener on unmount
    }, [navigate]);

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setEmail("");
            setPassword("");
        } catch (error) {
            alert("Login failed: " + error.message);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate("/"); // Redirect to the WelcomePage
        } catch (error) {
            alert("Logout failed: " + error.message);
        }
    };

    return (
        <header className="bg-gray-800 text-white p-3 flex justify-between items-center">
            {/* Logo */}
            <div className="text-2xl font-bold cursor-pointer" onClick={() => navigate("/")}>
                AppQwest
            </div>

            {/* User Controls */}
            <div className="flex items-center space-x-4">
                {user ? (
                    <div className="flex items-center space-x-4">
                        {/* User Info */}
                        <span className="text-sm font-medium">{user.email}</span>
                        {/* Logout Button */}
                        <button
                            onClick={handleLogout}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                        >
                            Disconnect
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center space-x-2">
                        {/* Login Form */}
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                        />
                        <button
                            onClick={handleLogin}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                        >
                            Connect
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default React.memo(Header);
