import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, collection, getDocs, getDoc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../firebaseConfig";

const Achievements = () => {
    const [achievements, setAchievements] = useState([]);
    const navigate = useNavigate();

    // Fetch achievements from Firestore
    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const achievementsRef = collection(db, "achievements");
                const snapshot = await getDocs(achievementsRef);

                const data = snapshot.docs.map((doc) => ({
                    firestoreDocId: doc.id, // Firestore document ID
                    ...doc.data(), // All other fields, including the `id` field inside the document
                }));

                setAchievements(data);
            } catch (error) {
                console.error("Error fetching achievements:", error);
            }
        };

        fetchAchievements();
    }, []);

    // Handle delete achievement
    const handleDelete = async (firestoreDocId, imageUrl) => {
        if (window.confirm("Are you sure you want to delete this achievement?")) {
            try {
                // Optionally delete the image from Firebase Storage
                if (imageUrl) {
                    console.log("Deleting image from storage:", imageUrl);
                    const imageRef = ref(storage, imageUrl);
                    await deleteObject(imageRef);
                    console.log("Image deleted successfully.");
                }

                // Delete the Firestore document using the Firestore document ID
                console.log("Deleting Firestore document with ID:", firestoreDocId);
                const docRef = doc(db, "achievements", firestoreDocId);
                await deleteDoc(docRef);
                console.log("Firestore document deleted successfully.");

                // Update local state
                setAchievements((prev) => prev.filter((ach) => ach.firestoreDocId !== firestoreDocId));
                alert("Achievement deleted successfully.");
            } catch (error) {
                console.error("Error deleting achievement:", error);
                alert("Failed to delete achievement.");
            }
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Achievements</h1>
            <button
                onClick={() => navigate("/gamification/achievements/create")}
                className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 mb-4"
            >
                Create
            </button>

            <div className="space-y-4">
                {achievements.map((achievement) => (
                    <div
                        key={achievement.firestoreDocId} // Use Firestore document ID as the key
                        className="p-4 border rounded-md shadow-md flex items-center space-x-4"
                    >
                        <img
                            src={achievement.imageUrl || "https://via.placeholder.com/100"}
                            alt={achievement.name}
                            className="w-20 h-20 object-cover rounded-md"
                        />
                        <div className="flex-grow">
                            <h2 className="text-xl font-semibold">{achievement.name}</h2>
                            <p className="text-sm text-gray-600">{achievement.description}</p>
                            <p className="text-sm text-gray-800 font-bold">
                                Reward: {achievement.rewardValue}
                            </p>
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() =>
                                    navigate(`/gamification/achievements/edit/${achievement.firestoreDocId}`)
                                }
                                className="bg-yellow-500 text-white px-3 py-1 rounded-md shadow hover:bg-yellow-600"
                            >
                                Edit
                            </button>
                            <button
                                onClick={() =>
                                    handleDelete(achievement.firestoreDocId, achievement.imageUrl)
                                }
                                className="bg-red-600 text-white px-3 py-1 rounded-md shadow hover:bg-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Achievements;
