import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { doc, getDoc, collection, query, where, getDocs, setDoc } from "firebase/firestore";

const CreateCampaign = () => {
  const [campaignName, setCampaignName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("");
  const [apps, setApps] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [appDropdownOpen, setAppDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const dropdownRef = useRef(null);

  // Fetch user's companyId
  useEffect(() => {
    const fetchCompanyId = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) return;

      const userDocRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        setCompanyId(userData.companyId);
      } else {
        console.error("User document not found");
      }
    };

    fetchCompanyId();
  }, []);

  // Fetch apps filtered by companyId
  useEffect(() => {
    const fetchApps = async () => {
      try {
        if (!companyId) return;

        const appListQuery = query(collection(db, "appList"), where("companyId", "==", companyId));
        const appListSnap = await getDocs(appListQuery);

        const companyApps = appListSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data().apps, // Extract app data stored in the `apps` field
        }));

        setApps(companyApps);
      } catch (error) {
        console.error("Error fetching apps:", error);
      }
    };

    fetchApps();
  }, [companyId]);

  const toggleAppDropdown = () => {
    setAppDropdownOpen(!appDropdownOpen);
  };

  const handleAppSelection = (app) => {
    if (!selectedApps.find((selected) => selected.id === app.id)) {
      setSelectedApps((prev) => [...prev, app]);
    }
  };

  const removeSelectedApp = (appId) => {
    setSelectedApps((prev) => prev.filter((app) => app.id !== appId));
  };

  // Handle click outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAppDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const saveCampaign = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("You need to be logged in to save a campaign.");
      return;
    }

    if (!companyId) {
      alert("Company ID is not available. Please check user data.");
      return;
    }

    if (!campaignName || !startDate || !endDate || !budget) {
      alert("All fields are required.");
      return;
    }

    try {
      const campaignsRef = collection(db, "campaigns");
      const campaignsSnapshot = await getDocs(campaignsRef);
      const nextCampaignId = `${campaignsSnapshot.size + 1}`; // Incremental ID

      const campaignData = {
        id: nextCampaignId,
        userId: user.uid,
        companyId, // Include the company ID
        name: campaignName,
        startDate,
        endDate,
        budget,
        apps: selectedApps.map((app) => app.id), // Save app IDs
      };

      const campaignDocRef = doc(db, "campaigns", nextCampaignId); // Create a document reference
      await setDoc(campaignDocRef, campaignData); // Save campaign to Firestore
      alert("Campaign saved successfully!");
    } catch (error) {
      console.error("Error saving campaign:", error);
      alert("Failed to save campaign.");
    }
  };

  // Filter apps based on the search term
  const filteredApps = apps.filter((app) =>
    app.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Campaign Creation</h1>

      {/* Campaign Name */}
      <div className="mb-4">
        <h2 className="font-semibold mb-2">Campaign Name</h2>
        <input
          type="text"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Apps Multi-Select */}
      <div className="mb-4 relative" ref={dropdownRef}>
        <h2 className="font-semibold mb-2">Apps to Advertise</h2>
        <div
          className="flex items-center flex-wrap gap-2 border p-2 rounded cursor-pointer"
          onClick={toggleAppDropdown}
        >
          {selectedApps.map((app) => (
            <div
              key={app.id}
              className="flex items-center bg-gray-200 rounded-full px-3 py-1"
            >
              <img
                src={app.icon || "https://via.placeholder.com/40"}
                alt={app.title}
                className="w-6 h-6 rounded-full mr-2"
              />
              <span className="text-sm font-medium">{app.title}</span>
              <button
                onClick={() => removeSelectedApp(app.id)}
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
          ))}
          <span className="ml-auto text-gray-400">▼</span>
        </div>
        {appDropdownOpen && (
          <div className="absolute left-0 mt-1 w-full border rounded bg-white shadow-lg max-h-60 overflow-y-auto z-20">
            {/* Search Input */}
            <div className="p-2">
              <input
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {/* Filtered App List */}
            {filteredApps.map((app) => (
              <div
                key={app.id}
                onClick={() => handleAppSelection(app)}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
              >
                <img
                  src={app.icon || "https://via.placeholder.com/40"}
                  alt={app.title}
                  className="w-6 h-6 rounded-full mr-2"
                />
                <span className="font-semibold text-sm">{app.title}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Start and End Dates */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <h2 className="font-semibold mb-2">Start Date</h2>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <h2 className="font-semibold mb-2">End Date</h2>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {/* Budget */}
      <div className="mb-4">
        <h2 className="font-semibold mb-2">Budget</h2>
        <div className="relative flex items-center border rounded focus-within:ring-2 focus-within:ring-blue-500">
          <span className="absolute left-4 text-gray-500">$</span>
          <input
            type="number"
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
            className="w-full pl-8 pr-12 py-2 border-none focus:outline-none"
            placeholder="0.00"
          />
          <span className="absolute right-4 text-gray-500">USD</span>
        </div>
      </div>

      <div className="mt-6">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={saveCampaign}
        >
          Save Campaign
        </button>
      </div>
    </div>
  );
};

export default CreateCampaign;
