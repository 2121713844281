import React from 'react';

const Settings = () => {
    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold">Settings</h1>
            <p>Settings page coming soon...</p>
        </div>
    );
};

export default Settings;