import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(20);
    const [sortConfig, setSortConfig] = useState({ key: "email", direction: "asc" });
    const [search, setSearch] = useState("");
    const [editingUser, setEditingUser] = useState(null); // User being edited
    const [newRole, setNewRole] = useState(""); // Role to update

    const roles = ["admin", "publisher-admin", "publisher", "user"]; // List of roles

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const fetchedUsers = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUsers(fetchedUsers);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    const sortData = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
        const sortedUsers = [...users].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setUsers(sortedUsers);
    };

    const handleStatusChange = async (id, currentStatus) => {
    try {
        const newStatus = currentStatus === "active" ? "inactive" : "active";

        // Update the user's status in Firestore
        await updateDoc(doc(db, "users", id), { status: newStatus });

        // Update local state to reflect the change
        setUsers(users.map((user) =>
            user.id === id ? { ...user, status: newStatus } : user
        ));

        alert(`User ${id} has been ${newStatus === "active" ? "reactivated" : "deactivated"}.`);
    } catch (error) {
        console.error("Error updating user status:", error);
        alert("Failed to update user status.");
    }
};

    const handleEditRole = (user) => {
        setEditingUser(user);
        setNewRole(user.role); // Pre-fill with the current role
    };

    const handleRoleChange = async () => {
        if (!editingUser || !newRole) return;

        try {
            // Update the user's role in Firestore
            await updateDoc(doc(db, "users", editingUser.id), { role: newRole });

            // Update local state
            setUsers(users.map((user) =>
                user.id === editingUser.id ? { ...user, role: newRole } : user
            ));

            setEditingUser(null); // Close the modal
            alert(`User role updated to ${newRole}`);
        } catch (error) {
            console.error("Error updating role:", error);
            alert("Failed to update role.");
        }
    };

    const filteredUsers = users.filter((user) =>
        user.email.toLowerCase().includes(search.toLowerCase())
    );

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">User Management</h1>

            {/* Search Field */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search by email..."
                    className="px-4 py-2 border rounded-md w-full"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            {/* Table */}
            <div className="border rounded-md">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => sortData("email")}
                            >
                                Email {sortConfig.key === "email" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => sortData("role")}
                            >
                                Role {sortConfig.key === "role" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                onClick={() => sortData("status")}
                            >
                                Status {sortConfig.key === "status" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {currentUsers.map((user) => (
                            <tr key={user.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.email}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.role}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {user.status}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    <button
                                        className="text-blue-500 hover:underline mr-4"
                                        onClick={() => handleEditRole(user)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className={`text-${
                                            user.status === "active" ? "yellow" : "green"
                                        }-500 hover:underline`}
                                        onClick={() => handleStatusChange(user.id, user.status)}
                                    >
                                        {user.status === "active" ? "Deactivate" : "Reactivate"}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="mt-4 flex justify-center space-x-2">
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                    <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={`px-3 py-1 border rounded-md ${
                            pageNumber === currentPage
                                ? "bg-blue-600 text-white"
                                : "bg-gray-200 hover:bg-gray-300"
                        }`}
                    >
                        {pageNumber}
                    </button>
                ))}
            </div>

            {/* Edit Role Modal */}
            {editingUser && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Edit Role for {editingUser.email}</h2>
                        <select
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}
                            className="w-full border rounded-md px-4 py-2 mb-4"
                        >
                            {roles.map((role) => (
                                <option key={role} value={role}>
                                    {role}
                                </option>
                            ))}
                        </select>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setEditingUser(null)}
                                className="px-4 py-2 mr-2 bg-gray-300 rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleRoleChange}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Users;
