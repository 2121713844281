// src/pages/Index.js
import React from "react";
import { useNavigate } from "react-router-dom";

const Index = () => {
    const navigate = useNavigate();

    const handleSignUpClick = () => {
        navigate("/signup"); // Navigate to sign-up page
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold mb-4">Welcome to AppQwest!</h1>
            <p className="mb-6">
            </p>
        </div>
    );
};

export default Index;
